import { useEffect, RefObject } from 'react';

export const  useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  handler: (event: Event) => void,
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const target = event.target as HTMLButtonElement;

      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        target.classList.contains('dropdown-active')
      ) {
        return;
      }

      handler(event);
    };

    const escHandler = (event: KeyboardEvent) => {
      if (event.key  === 'Escape') {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('keydown', escHandler);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('keydown', escHandler);
    };
  }, [ref, handler]);
};
