import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Header } from '../Header';
import { Footer } from 'src/components/Footer';
import './Layout.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  landingPage?: boolean;
  noFooter?: boolean;
}

export const Layout = ({
  children,
  className,
  landingPage,
  noFooter,
}: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={className}>
      <Header
        className={[landingPage ? 'on-landing-page' : ''].join(' ')}
      />
      <main
        className={[landingPage ? 'on-landing-page' : ''].join(' ')}
      >
        {children}
      </main>
      {
        !noFooter && <Footer />
      }
    </div>
  );
};
