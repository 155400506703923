import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { Dropdown } from 'src/components/Dropdown';
import './Header.scss';

import logo from 'src/assets/images/alviso-logo.svg';
import logoMob from 'src/assets/images/alviso-logo-mob.svg';
import recruiter from 'src/assets/images/recruiter/step2.png';
import scout from 'src/assets/images/scout/step1.png';
import bot from 'src/assets/images/bot/bot.png';
import contractors from 'src/assets/images/contractors/contractors.png';

interface Props {
  className?: string;
}

export const Header = ({ className }: Props) => {
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const [teamDropdownActive, setTeamDropdownActive] = useState<boolean>(false);
  const [joinDropdownActive, setJoinDropdownActive] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.location.pathname === '/team' ||
      window.location.pathname === '/augmented-recruitment' ||
      window.location.pathname === '/bot' ||
      window.location.pathname === '/contractors' ||
      window.location.pathname === '/headhunting'
    ) {
      setTeamDropdownActive(true);
    }

    if (
      window.location.pathname === '/recruiter' ||
      window.location.pathname === '/scout'
    ) {
      setJoinDropdownActive(true);
    }
  }, []);

  return (
    <header
      className={[
        'header', className,
        showMobile ? 'open' : '',
      ].join(' ')}
    >
      <div className="container d-flex justify-content-between align-items-center pt-3">
        <Link
          onClick={() => setShowMobile(false)}
          to='/'
        >
          <img className="header__logo d-none d-lg-block" src={logo} alt="Alviso" />
          <img className="header__logo d-lg-none" src={logoMob} alt="Alviso" />
        </Link>
        <nav className="header__nav">
          <Link to='/family' activeClassName="active">Alviso family</Link>
          <Dropdown
            showToggle={true}
            className={[teamDropdownActive ? 'active' : '', 'ml-6'].join(' ')}
            dropdownItems={[
              {
                text: 'HOW IT WORKS',
                link: '/team',
              },
              {
                image: scout,
                text: 'AUGMENTED RECRUITMENT',
                link: '/augmented-recruitment',
              },
              {
                image: bot,
                text: 'BUILD - OPERATE - TRANSFER',
                link: '/bot',
              },
              {
                image: contractors,
                text: 'CONTRACTORS',
                link: '/contractors',
              },
              {
                image: scout,
                text: 'HEADHUNTING',
                link: '/headhunting',
              },
            ]}
          >
            <a href="#">Grow your team!</a>
          </Dropdown>
          <Link to={'/career'} activeClassName="active">Your career</Link>
          <Link to={'/references'} activeClassName="active">References</Link>
          <Link to={'/contact'} activeClassName="active">Contact</Link>
          <Dropdown
            showToggle={true}
            className={[joinDropdownActive ? 'active' : '', 'ml-6'].join(' ')}
            dropdownItems={[
              {
                image: recruiter,
                text: 'as RECRUITER',
                link: '/recruiter',
              },
              {
                image: scout,
                text: 'as TALENT SCOUT',
                link: '/scout',
              },
            ]}
          >
            <a>Join alviso</a>
          </Dropdown>
        </nav>
        <div
          className={[
            'header__burger',
            className,
            showMobile ? 'open' : '',
          ].join(' ')}
          onClick={() => setShowMobile(!showMobile)}
        >
          <div />
          <div />
          <div />
        </div>
      </div>
      {
        showMobile &&
          <div className="header__mobile-menu container pt-5">
            <Link
              to='/family'
              onClick={() => setShowMobile(false)}
            >
              Alviso family
            </Link>
            <Dropdown
              showToggle={true}
              className="mb-2 ml-6"
              dropdownItems={[
                {
                  image: recruiter,
                  text: 'HOW IT WORKS',
                  link: '/team',
                },
                {
                  image: scout,
                  text: 'AUGMENTED RECRUITMENT',
                  link: '/augmented-recruitment',
                },
                {
                  image: recruiter,
                  text: 'BUILD - OPERATE - TRANSFER',
                  link: '/bot',
                },
                {
                  image: scout,
                  text: 'CONTRACTORS',
                  link: '/contractors',
                },
                {
                  image: scout,
                  text: 'HEADHUNTING',
                  link: '/headhunting',
                },
              ]}
            >
              <a href="#">Grow your team!</a>
            </Dropdown>
            <Link
              to='/career'
              onClick={() => setShowMobile(false)}
            >
              Your career
            </Link>
            <Link
              to='/references'
              onClick={() => setShowMobile(false)}
            >
                  References
            </Link>
            <Link
              to='/contact'
              onClick={() => setShowMobile(false)}
            >
              Contact
            </Link>
            <Dropdown
              showToggle={true}
              className="ml-6"
              dropdownItems={[
                {
                  image: recruiter,
                  text: 'as RECRUITER',
                  link: '/recruiter',
                },
                {
                  image: scout,
                  text: 'as TALENT SCOUT',
                  link: '/scout',
                },
              ]}
            >
              <a>Join alviso</a>
            </Dropdown>
          </div>
      }
    </header>
  );
};
