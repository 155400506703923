import * as React from 'react';
import { Link } from 'gatsby';
import SVG from 'react-inlinesvg';

import facebook from 'src/assets/icons/facebook.svg';
import instagram from 'src/assets/icons/instagram.svg';
import linkedin from 'src/assets/icons/linkedin.svg';

import './Footer.scss';

export const Footer = () => (
  <footer className="footer">
    <div className="container h-100">
      <div className="row h-100">
        <div className="d-flex align-items-center justify-content-start col-6 col-sm-4 col-md-5 col-lg-6">
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/alviso/"
          >
            <SVG className="footer__icon" src={linkedin} />
          </a>
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/alvisorecruitment/"
          >
            <SVG className="footer__icon" src={facebook} />
          </a>
          <a
            className="mr-2"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/alviso.cz/"
          >
            <SVG className="footer__icon" src={instagram} />
          </a>
        </div>
        <div className="d-flex flex-column align-items-end justify-content-center col-6 col-sm-4 col-md-3">
          <div className="d-flex flex-column">
            <p>Michaela Huláková</p>
            <a href="mailto:info@alviso.cz">info@alviso.cz</a>
            <a href="tel:00420739534701">+420 739 534 701</a>
          </div>
        </div>
        <div className="d-none d-sm-flex flex-column justify-content-center align-items-end col-sm-4 col-lg-3 text-align-right">
          <div>
            <p><strong>Alviso Recruitment s.r.o.</strong></p>
            <p>Janáčkovo nábřeží 133/61</p>
            <p>150 00 Praha 5 - Malá Strana</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
