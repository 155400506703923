import * as React from 'react';
import {
  ReactNode,
  MouseEvent,
  useRef,
  useState, useCallback,
} from 'react';
import { Link } from 'gatsby';
import SVG from 'react-inlinesvg';

import { useClickOutside } from 'src/hooks/useClickOutside';
import './Dropdown.scss';

import toggle from 'src/assets/icons/chevron-down.svg';

interface Props {
  children: ReactNode;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  showToggle?: boolean
  dropdownItems: {
    text:  string;
    image?: string;
    link: string;
  }[]
}

export const Dropdown = ({
  children,
  className,
  onClick,
  dropdownItems,
  showToggle,
}: Props) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [show, setShow] = useState<boolean>(false);
  useClickOutside(ref, () => setShow(false));

  const clickHandler = useCallback(e => {
    e.preventDefault();
    setShow(!show);

    if (onClick) {
      onClick(e);
    }
  }, [show]);

  return (
    <div
      ref={ref}
      className={[
        'dropdown',
        className,
      ].join(' ')}
    >
      <div
        className={['dropdown__toggle', show ? 'open' : ''].join(' ')}
        onClick={e => clickHandler(e)}
      >
        {children}
        {
          showToggle &&
            <SVG
              className={[show ? 'rotate' : ''].join(' ')}
              src={toggle}
            />
        }
      </div>
      <div
        ref={ref}
        className={[
          'dropdown__menu',
          show ? 'show' : '',
        ].join(' ')}
      >
        {
          dropdownItems.map((item, index) => (
            <div
              key={index}
              className={['dropdown__item', !item.image ? 'no-image' : ''].join(' ')}
            >
              {
                item.image && <img src={item.image} alt="Graphic" />
              }
              <span>{item.text}</span>
              <Link to={item.link} />
            </div>
          ))
        }
      </div>
    </div>
  );
};
